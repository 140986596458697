import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext';
import { collection, onSnapshot, query, orderBy, limit } from '@firebase/firestore';
import { db } from '../../config/firebase';
import { Navigate } from 'react-router';
import { TransactionResource } from '../../types/Up';
import TransactionCard from './TransactionCard';

import "./Transactions.css";

export default function Transactions() {

  const { loading, profile } = useUser();
  const [transactions, setTransactions] = useState<TransactionResource[]>([]);

  useEffect(() => {
    if (!profile) return;
    const collectionRef = collection(db, `data/${profile?.uid}/transactions`);
    const q = query(collectionRef, orderBy("attributes.createdAt", "desc"), limit(20));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setTransactions(snapshot.docs.map(doc => doc.data() as TransactionResource));
    })
    return () => {
      unsubscribe();
    }
  }, [profile]);

  if (loading) return <div>Loading...</div>

  if (!profile) return (
    <Navigate to="/home" />
  )

  return (
    <div className="transactions container">
      {/* Display transactions */}
      {transactions.map(transaction => (
        <TransactionCard key={transaction.id} transaction={transaction} />
      ))}
    </div>
  )
}
