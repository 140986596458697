import React, { useEffect } from 'react'
import { Navigate } from 'react-router';
import { useUser } from '../../context/UserContext';

export default function Logout() {

    const { authUser, logout } = useUser();
    
    useEffect(() => {
        logout();
    }, []);

    if (!authUser) {
        return <Navigate to='/home' />
    }

    return (
        <div>Goodbye</div>
    )
}
