import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBhWcCpfENk2GUDL_Q7bjJTJ4yKmcaW7aU",
    authDomain: "up-app-117bd.firebaseapp.com",
    projectId: "up-app-117bd",
    storageBucket: "up-app-117bd.appspot.com",
    messagingSenderId: "150395932074",
    appId: "1:150395932074:web:1b637b12353473d2e3efd3",
    measurementId: "G-GQNR7ZSYH9"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);
export const storage = getStorage(app);