import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext';
import { collection, onSnapshot } from '@firebase/firestore';
import { db } from '../../config/firebase';
import { Navigate } from 'react-router';

export default function Events() {

  const { loading, profile } = useUser();
  const [events, setEvents] = useState<any[]>([]);

  useEffect(() => {
    if (!profile) return;
    const collectionRef = collection(db, `data/${profile?.uid}/events`);
    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      setEvents(snapshot.docs.map(doc => doc.data()));
    })
    return () => {
      unsubscribe();
    }
  }, [profile]);

  if (loading) return <div>Loading...</div>

  if (!profile) return (
    <Navigate to="/home" />
  )

  return (
    <div className="container">
      {/* Display events */}
      {events.map((event, idx) => (
        <div key={idx}>
          <pre>
            {JSON.stringify(event, null, 2)}
          </pre>
          <hr />
        </div>
      ))}
    </div>
  )
}
