import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext'
import { Navigate, useNavigate } from 'react-router';
import { collection, onSnapshot } from '@firebase/firestore';
import { db } from '../../config/firebase';
import HomeLogged from './HomeLogged';

/*
This is the home page.
*/

export default function Home() {

  const { loading, profile } = useUser();

  const navigate = useNavigate();

  if (loading) return <div>Loading...</div>

  if (!profile) return (
    /* Display this content when user is not logged in */
    <div className="container center-align">
      <p>Display this content when user is not logged in</p>
    </div>
  )

  // Check if the profile is ready
  if (!profile.upToken || !profile.upWebhookId) {
    return <Navigate to="/profile" />
  }

  const style = { margin: '10px' }
  return (
    /* Display this content when user is logged in */
    <>
      <HomeLogged />
    </>
  )
}
