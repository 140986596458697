import React, { FormEvent, Profiler, useCallback, useState } from 'react'
import { useUser } from '../../context/UserContext';
import Loading from '../Loading/Loading';
import './Profile.css';
import { Navigate } from 'react-router';
import { db } from '../../config/firebase';
import { doc, updateDoc } from '@firebase/firestore';


export default function Profile() {

    const { loading, authUser, profile } = useUser();

    const [upToken, setUpToken] = useState(profile?.upToken || '');


    const handleSaveToken = useCallback(() => {

        if (!authUser) return;

        console.log('saving token', upToken);

        // Get the user and update the upToken
        const profileRef = doc(db, 'users', authUser.uid);
        updateDoc(profileRef, { upToken })
            .then(() => {
                console.log('token saved');
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setUpToken('');
                console.log('token saved');
            });

    }, [upToken, authUser]);


    const handleDeleteToken = useCallback(() => {
        if (!authUser) return;

        console.log('deleting token');
        // Get the user and update the upToken
        const profileRef = doc(db, 'users', authUser.uid);
        updateDoc(profileRef, { upToken: null })
            .then(() => {
                console.log('token deleted');
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setUpToken('');
                console.log('token deleted');
            });
    }, [authUser]);



    if (loading) return (<Loading />)

    if (!authUser) return (<Navigate to="/home" />)

    if (!profile) return (<Loading />)

    return (
        <div className='profile'>

            {/* Side bar */}
            <div className='sidebar up-blue-1 text-up-grey opacity-75 center-align'>
                <div className="profile-image">
                    <img src={authUser.photoURL || 'user_avatar.png'} />
                </div>
                <p className='text-up-orange center-align' style={{ fontSize: '1.5rem' }}>{authUser.displayName}</p>

                <div className="opacity-75">
                    {profile.upWebhookId && (
                        <div>
                            <p>Webhook configured</p>
                        </div>
                    )}
                </div>

                <div className="opacity-75">
                    {profile.accountsFetched && (
                        <div>
                            <p>Accounts fetched</p>
                        </div>
                    )}
                </div>

                <div className="opacity-100">
                    {profile.upToken && (
                        <div className='center-align'>
                            <div className="opacity-75">Token configured</div>
                            <div className="up-btn" style={{
                                width: '140px',
                                marginTop: '20px',
                                marginBottom: '20px',
                                marginLeft: '30px'
                            }} onClick={handleDeleteToken}>Delete token</div>
                        </div>
                    )}
                </div>

            </div>

            {/* Main content */}
            <div className='main-content'>
                <div className="box text-up-grey">
                    {!profile.upToken && (
                        <>
                            <h5 className="white-text">Up Token</h5>
                            <form className='center-align'>
                                <label htmlFor="up-token">Up Token</label>
                                <input className="text-up-grey opacity-75" style={{ textOverflow: 'ellipsis' }} type="text" id="up-token" value={upToken} onChange={(e) => setUpToken(e.target.value)} placeholder="enter an API token to get started" />
                                {upToken && <div className="up-btn" style={{ width: '200px', margin: 'auto' }} onClick={() => handleSaveToken()}>Save</div>}
                            </form>
                        </>
                    )}
                </div>
                <div className="box">
                </div>
            </div>
        </div >
    )
}
