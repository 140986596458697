import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import StyledFirebaseAuth from './StyledFirebaseAuth';
import { GoogleAuthProvider, FacebookAuthProvider, EmailAuthProvider } from '@firebase/auth';
import { useUser } from '../../context/UserContext';

// Configure FirebaseUI.
const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // popupMode: true,
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/home',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        // FacebookAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
    ],
};

function SignIn() {

    const { authUser, loading } = useUser();

    const navigate = useNavigate();

    // If the user is already logged in, redirect to the home page
    console.log('authUser')
    console.log(authUser)
    if (!loading && authUser) {
        return <Navigate to="/" />
    }

    return (
        <div className="container">
            <div className="container center-align">
                <h4 className='center-align'>Sign in</h4>
                
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} className='container' />
                
                {/* Log in button */}
                <br />
                <p>Already have an account? <a href="/login" onClick={() => navigate("/login")}>Login</a></p>

            </div>
        </div >
    );
}

export default SignIn