import React, { useCallback, useMemo } from 'react'
import { TransactionResource } from '../../types/Up'
import { useUser } from '../../context/UserContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';


export default function TransactionCard(props: { transaction: TransactionResource }) {
  const { transaction } = props;
  const { profile } = useUser();

  const dateString = useMemo(() => {
    const date = new Date(transaction.attributes.createdAt)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  },
    [transaction.attributes.createdAt]
  )

  const comment = useMemo(() => {
    if (transaction.attributes.message) {
      return `, ${transaction.attributes.message}`
    }
    return ""
  }, [transaction.attributes.message]);

  const [amount, classColour] = useMemo(() => {
    let ammount = Number(transaction.attributes.amount.value);
    // If it has decimal places, round to 2. Else round to 0.
    if (ammount % 1 !== 0) {
      ammount = Math.round(ammount * 100) / 100;
    } else {
      ammount = Math.round(ammount);
    }
    if (ammount >= 0) {
      return [`+$${ammount}`, 'green-text'];
    } else {
      return [`$${-ammount}`, ''];
    }
  }, [transaction.attributes.amount.value]);

  const handleSummarise = useCallback(() => {
    if (!profile) {
      return;
    }
    // Update the doc in Firestore
    const docRef = doc(db, `data/${profile.uid}/transactions/${transaction.id}`);
    updateDoc(docRef, {
      summarise: true
    });
  }, [profile, transaction]);

  return (
    <>
      <div className='hide-on-med-and-up'>
        <div className='transaction-card up-grey-2s white black-text' style={{ margin: '5px' }}>
          <div className="opacity-75" style={{ fontSize: '16px', fontWeight: 'semibold', paddingLeft: '10px', paddingTop: '10px' }}>{transaction.attributes.description}</div>
          <div className="opacity-50" style={{ fontSize: '12px', paddingLeft: '10px' }}>
            {dateString} {comment}
          </div>
          <div className={classColour} style={{ fontSize: '16px', fontWeight: 'semibold', paddingLeft: '10px' }}>
            {amount}
          </div>
          {transaction.aiFields ? (
            <>
              <div className='divider center-align' style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px', marginBottom: '10px' }} />
              <div style={{ fontSize: '12px', paddingLeft: '10px' }}>
                {transaction.aiFields.summary}
              </div>
            </>
          ) : ''}
          {transaction.summarise ? (
            <div className="center-align opacity-25" style={{ padding: '10px' }}>
              <img src='/magic.png' style={{ width: '32px', height: '32px' }} />
            </div>
          ) : (
            <div className="center-align opacity-100" style={{ padding: '10px' }} onClick={handleSummarise}>
              <img src='/magic.png' style={{ width: '32px', height: '32px', cursor: 'pointer' }} />
            </div>
          )}
        </div>
      </div>
      <div className='hide-on-small-and-down'>
        <div className='row transaction-card up-grey-2s white black-text' style={{ margin: '5px' }}>
          <div className="col s1">
            {transaction.summarise ? (
              <div className="center-align opacity-25" style={{ padding: '10px' }}>
                <img src='/magic.png' style={{ width: '32px', height: '32px' }} />
              </div>
            ) : (
              <div className="center-align opacity-100" style={{ padding: '10px' }} onClick={handleSummarise}>
                <img src='/magic.png' style={{ width: '32px', height: '32px', cursor: 'pointer' }} />
              </div>
            )}
          </div>
          <div className="col s9">
            <div style={{ padding: '10px' }}>
              <div className="opacity-75" style={{ fontSize: '16px', fontWeight: 'semibold' }}>{transaction.attributes.description}</div>
              <div className="opacity-50" style={{ fontSize: '12px' }}>
                {dateString} {comment}
              </div>
              <div>
                {transaction.aiFields && transaction.aiFields.summary}
              </div>
            </div>
          </div>
          <div className="col s2">
            <div style={{ padding: '10px' }}>
              <div className={classColour} style={{ fontSize: '16px', fontWeight: 'semibold', textAlign: 'right' }}>
                {amount}
              </div>
            </div>
          </div>
        </div >
      </div>
    </>
  )
}
