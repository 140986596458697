import React, { useMemo } from 'react'
import { AccountResource } from '../../types/Up'

export default function AccountCard(props: { account: AccountResource, selected?: boolean }) {
  const { account, selected } = props;

  const [amount, classColour] = useMemo(() => {
    let ammount = Number(account.attributes.balance.value);
    // If it has decimal places, round to 2. Else round to 0.
    if (ammount % 1 !== 0) {
      ammount = Math.round(ammount * 100) / 100;
    } else {
      ammount = Math.round(ammount);
    }
    if (ammount >= 0) {
      return [`$${ammount}`, ''];
    } else {
      return [`-$${-ammount}`, 'red-text'];
    }
  }, [account.attributes.balance.value]);

  return (
    <>
      <div className='row transaction-card up-grey-2s white black-text' style={{ margin: '5px', borderLeft: `5px solid ${selected ? 'rgb(255, 122, 100)' : 'transparent'}` }}>
        <div className='col s7'>
          <div style={{ padding: '10px' }}>
            <div className="opacity-75" style={{ fontSize: '16px', fontWeight: 'semibold' }}>{account.attributes.displayName}</div>
          </div>
        </div>
        <div className="col s5">
          <div style={{ padding: '10px' }}>
            <div className={classColour} style={{ fontSize: '16px', fontWeight: 'semibold', textAlign: 'right' }}>
              {amount}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
