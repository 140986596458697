import React, { useEffect, useMemo } from 'react'

import './Nav.css';
import { useUser } from '../../context/UserContext';
import { Link, useLocation } from 'react-router-dom';
import M from 'materialize-css';

/*
This navbar will always be shown on top of the page.
It will show different options depending on the user.

User is not authenticated:
- Show login or signup button

User is authenticated:
- Show profile button
- Show logout button
*/

const links = [
    ["/home", "Home"],
    ["/accounts", "Accounts"],
    ["/transactions", "Transactions"],
    ["/events", "Events"],
    ["/profile", "Profile"],
]

export default function Nav() {

    const { authUser } = useUser();

    const location = useLocation();

    const tab = useMemo(() => {
        return location.pathname.split('/')[1]
    }, [location]);

    useEffect(() => {
        // Init navbar
        var elems = document.querySelectorAll('.sidenav');
        var instances = M.Sidenav.init(elems, {});
        return () => {
            instances.forEach(instance => {
                instance.destroy();
            });
        }
    }, [authUser])

    const [mainLinkPath, mainLinkText] = useMemo(() => {
        let link = links[0];
        links.every(thisLink => {
            if (thisLink[0] == `/${tab}`) {
                link = thisLink;
                return false;
            } else {
                return true;
            }
        });
        return link;
    }, [tab]);

    return (
        <nav className='nav nav-wrapper up-grey-1'>

            <Link to={mainLinkPath} className="text-up-orange" style={{ marginLeft: '10px', fontSize: '30px' }}>{mainLinkText}</Link>

            {authUser ? (
                <>
                    {/* Main nav bar */}
                    <a href="#" data-target="sidenav" className="sidenav-trigger hide-on-med-and-up right"><i className="material-icons">menu</i></a>
                    <ul className="right hide-on-small-and-down">
                        {links.map((link, idx) => {
                            return <li key={idx}><Link to={link[0]} className={tab == link[0].split('/')[1] ? "text-up-orange" : ""}>{link[1]}</Link></li>
                        })}
                        <li><Link to="logout">Logout</Link></li>
                    </ul>
                    {/* NavBar for mobile */}
                    <ul className="sidenav up-grey-2" id="sidenav">
                        {links.map((link, idx) => {
                            return <li key={idx}><Link to={link[0]} className={tab == link[0].split('/')[1] ? "text-up-orange opacity-100" : "opacity-75"}>{link[1]}</Link></li>
                        })}
                        <li><Link to="logout">Logout</Link></li>
                    </ul>
                </>
            ) : (
                <>
                    <ul className='right'>
                        <li><Link to="signin">Sign in</Link></li>
                    </ul>
                </>
            )}
        </nav >
    )
}
