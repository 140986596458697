import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext'
import { collection, onSnapshot, query, orderBy, limit, where } from '@firebase/firestore';
import { AccountResource, TransactionResource } from '../../types/Up';
import { db } from '../../config/firebase';
import TransactionCard from '../Transactions/TransactionCard';
import AccountCard from '../Accounts/AccountCard';

export default function HomeLogged() {

  const { profile } = useUser();

  const [selectedAccount, setSelectedAccount] = useState<AccountResource | null>(null);

  // Grab accounts
  const [accounts, setAccounts] = useState<AccountResource[]>([]);
  useEffect(() => {
    if (!profile) return;
    const collectionRef = collection(db, `data/${profile?.uid}/accounts`);
    const q = query(collectionRef, orderBy("attributes.createdAt", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setAccounts(snapshot.docs.map(doc => doc.data() as AccountResource));
    })
    return () => {
      unsubscribe();
    }
  }, [profile]);

  // If no account is selected, select the one with displayName === "Spending"
  useEffect(() => {
    if (selectedAccount) return;
    const spendingAccount = accounts.find(account => account.attributes.displayName === "Spending");
    if (spendingAccount) {
      setSelectedAccount(spendingAccount);
    }
  }, [accounts]);


  // Grab transactions for the selected account
  const [transactions, setTransactions] = useState<TransactionResource[]>([]);
  useEffect(() => {
    console.log(`grabbing transactions for account id ${selectedAccount?.id}`);
    if (!profile) return;
    if (!selectedAccount) return;

    const collectionRef = collection(db, `data/${profile?.uid}/transactions`);
    const q = query(collectionRef,
      where("relationships.account.data.id", "==", selectedAccount?.id),
      orderBy("attributes.createdAt", "desc"), limit(20));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log(`got ${snapshot.docs.length} transactions for account ${selectedAccount?.id}`)
      setTransactions(snapshot.docs.map(doc => doc.data() as TransactionResource));
    })
    return () => {
      unsubscribe();
    }
  }, [profile, selectedAccount?.id]);

  return (
    <>
      {/* On large screens show the accounts list on the left and the transactions on the right */}
      <div className='hide-on-small-and-down'>
        <div className="row">
          <div className="col s12 m6">
            {/* Account list */}
            <h4>Accounts</h4>
            {accounts.map((account) => (
              <div style={{ cursor: "pointer" }} onClick={() => setSelectedAccount(account)}>
                <AccountCard key={account.id} account={account} selected={selectedAccount?.id === account.id} />
              </div>
            ))}
          </div>
          <div className="col s12 m6">
            {/* Transactions list */}
            <h4>Transactions</h4>
            {transactions.map((transaction) => (
              <TransactionCard transaction={transaction} key={transaction.id} />
            ))}
          </div>
        </div>
      </div>

      {/* On smaller screens, show the transactions under the selected account */}
      <div className='hide-on-med-and-up' style={{ marginLeft: '10px', marginRight: '10px' }}>
        {accounts.map((account) => (
          <>
            <div style={{ cursor: "pointer" }} onClick={() => {
              if (selectedAccount) {
                setSelectedAccount(null);
              } else {
                setSelectedAccount(account);
              }
            }}>
              <AccountCard key={account.id} account={account} selected={selectedAccount?.id === account.id} />
            </div>
            {account.id === selectedAccount?.id && (
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                {/* Transactions list */}

                {transactions.map((transaction) => (
                  <TransactionCard transaction={transaction} key={transaction.id} />
                ))}
              </div>
            )}
          </>
        ))}

      </div>
    </>
  )
}
