import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/UserContext';
import { collection, onSnapshot, query, orderBy } from '@firebase/firestore';
import { db } from '../../config/firebase';
import { Navigate } from 'react-router';
import { AccountResource } from '../../types/Up';
import AccountCard from './AccountCard';

import "./Accounts.css";

export default function Accounts() {

  const { loading, profile } = useUser();
  const [accounts, setAccounts] = useState<AccountResource[]>([]);

  useEffect(() => {
    if (!profile) return;
    const collectionRef = collection(db, `data/${profile?.uid}/accounts`);
    const q = query(collectionRef, orderBy("attributes.createdAt", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setAccounts(snapshot.docs.map(doc => doc.data() as AccountResource));
    })
    return () => {
      unsubscribe();
    }
  }, [profile]);

  if (loading) return <div>Loading...</div>

  if (!profile) return (
    <Navigate to="/home" />
  )

  return (
    <div className="accounts container">
      {/* Display accounts */}
      {accounts.map(account => (
        <AccountCard key={account.id} account={account} />
      ))}
    </div>
  )
}
