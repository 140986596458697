import { BrowserRouter } from "react-router-dom";
import Nav from './components/Nav/Nav';
import { UserProvider } from './context/UserContext';
import AppRoutes from './AppRoutes';

import './App.css';

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
        <div className="App up-grey-1">
          <header className="App-header">
            <Nav />
          </header>
          <AppRoutes />
        </div>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
