import React, { useState } from 'react'
import { signInWithEmailAndPassword } from '@firebase/auth';
import { auth } from '../../config/firebase';
import { useUser } from '../../context/UserContext';
import { Navigate, useNavigate } from 'react-router-dom';

export default function Login() {

    const navigate = useNavigate();

    const { authUser } = useUser();

    const [thinking, setThinking] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const loginWithEmail = async () => {
        setThinking(true);
        try {
            const authUser = await signInWithEmailAndPassword(auth, email, password);
        } catch (err) {
            // Bad credentials show as errors
            alert('bad credentials');
        }
        setThinking(false);
    }

    // If the user is already logged in, redirect to the home page
    if (authUser) {
        return <Navigate to="/" />
    }

    return (
        <div className="container">
            <div className="container center-align">
                <h3>Login</h3>
                <form onSubmit={e => e.preventDefault()}>
                    <div className="input-container">
                        <input
                            type="email"
                            placeholder='email'
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            className="email-input"
                        />
                    </div>
                    <div className="input-container">
                        <input
                            type="password"
                            placeholder='password'
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                            className="password-input"
                        />
                    </div>
                    <button className="btn signin-button blue" onClick={() => loginWithEmail()}>login</button>
                </form>
                
                {/* Sign in button */}
                <br />
                <p>Don't have an account? <a href="/signin" onClick={() => navigate("/signin")}>Sign in</a></p>

            </div>
        </div>
    )
}
