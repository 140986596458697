import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import { useUser } from './context/UserContext';

import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import Profile from './components/Profile/Profile';
import SignIn from './components/SignIn/SignIn';
import Events from './components/Events/Events';
import Transactions from './components/Transactions/Transactions';
import Accounts from './components/Accounts/Accounts';

type ProtectedProps = {
  condition: boolean | undefined;
  children: JSX.Element;
}

export default function AppRoutes() {

  return (
    <>
      <Routes>
        {/* General */}
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/home" element={<Home />} />

        {/* Auth */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        {/* Components */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/events" element={<Events />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/accounts" element={<Accounts />} />

        {/* 404 */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  )
}
